import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls } from 'app/config';
import { LoginUser } from 'app/models/login-user';
import { StorageService } from 'app/services/storage.service';
import { Utils } from 'app/utils';
import { Observable, lastValueFrom, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {

    constructor(
        private _httpClient: HttpClient,
        private _storageService: StorageService,
    ) {
    }

    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    signIn(credentials: { email: string; password: string }): Promise<LoginUser> {
        return lastValueFrom(this._httpClient.post<LoginUser>(ApiUrls.LOGIN_USER, credentials));
    }

    signOut() {
        this._storageService.clearLocalStorage();
    }

    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    check(): Observable<boolean> {

        let accessToken = this._storageService.getSessionToken();

        // Check the access token availability 
        if (!accessToken) {
            return of(false);
        }

        //and token expire date
        if (Utils.isTokenExpired(accessToken)) {
            return of(false);
        }

        return of(true);
    }
}
