import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls } from 'app/config';
import { BeVersionInfo } from 'app/models/be-version-info';
import { LatestBuilds } from 'app/models/latest-builds';
import { ChipInPaymentMethods, SystemConstValues } from 'app/models/system-const-values';
import { lastValueFrom } from 'rxjs';
import { StorageService } from './storage.service';
import { UtilService } from './util.service';

@Injectable({ providedIn: 'root' })
export class SystemService {

    constructor(
        private http: HttpClient,
        private _utilService: UtilService,
        private _storageService: StorageService,
    ) { }

    async getBeVersionInfo() {
        try {
            if (this._storageService.getSessionToken()) {
                let details = await this.getBeVersionDetails();
                this._utilService.beVersionObx.next(details?.version);
            }
        } catch (e) {
            console.error(e);
        }
    }

    async loadSystemConstValues() {
        try {
            if (this._storageService.getSessionToken()) {
                let details = await this.getSystemConstantValues();
                this._utilService.systemConstValuesObx.next(details);
            }
        } catch (e) {
            console.error(e);
        }
    }

    getSystemConstantValues(): Promise<SystemConstValues> {
        return lastValueFrom(this.http.get<SystemConstValues>(ApiUrls.SYSTEM_CONSTANT_VALUES));
    }

    getBeVersionDetails(): Promise<BeVersionInfo> {
        return lastValueFrom(this.http.get<BeVersionInfo>(`${ApiUrls.BE_INFO}`));
    }

    getLatestBuildsDetails(): Promise<LatestBuilds> {
        return lastValueFrom(this.http.get<LatestBuilds>(`${ApiUrls.SYSTEM_LATEST_BUILDS}`));
    }

    applyServerUpdate(): Promise<any> {
        return lastValueFrom(this.http.get<any>(`${ApiUrls.SYSTEM_APPLY_SERVER_UPDATE}`, {
            headers: { 'html': 'true' },
        }));
    }

    applyWebAdminUpdate(): Promise<any> {
        return lastValueFrom(this.http.get<any>(`${ApiUrls.SYSTEM_APPLY_WEB_ADMIN_UPDATE}`, {
            headers: { 'html': 'true' },
        }));
    }

    getLatestAvailableBuildsDetails(): Promise<LatestBuilds> {
        return lastValueFrom(this.http.get<LatestBuilds>(`${ApiUrls.SYSTEM_LATEST_AVAILABLE_BUILD}`));
    }

    getChipInPaymentMethods(chipInBrandId: string): Promise<ChipInPaymentMethods> {
        let params = new HttpParams();
        params = params.append('chipInBrandId', chipInBrandId);
        return lastValueFrom(this.http.get<ChipInPaymentMethods>(`${ApiUrls.SYSTEM_CHIP_IN_PAYMENT_METHODS}`, { params }));
    }
}
