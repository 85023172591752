import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginUser } from 'app/models/login-user';
import { SystemConstValues } from 'app/models/system-const-values';
import { User } from 'app/models/user';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class UtilService {

    public loginChangeObx: BehaviorSubject<User | null>;
    public beVersionObx: BehaviorSubject<string | null>;
    public systemConstValuesObx: BehaviorSubject<SystemConstValues | null>;

    constructor(private storageService: StorageService) {
        this.loginChangeObx = new BehaviorSubject<User | null>(this.storageService.getCurrentUser());
        this.beVersionObx = new BehaviorSubject<string | null>('v0.0.0');
        this.systemConstValuesObx = new BehaviorSubject<SystemConstValues | null>(null);
    }

    updateUserProfile(user: User) {
        this.storageService.setCurrentUser(user);
        this.loginChangeObx.next(user);
    }

    loginUser(rUser: LoginUser) {
        this.storageService.setSessionToken(rUser.token);
        this.storageService.setCurrentUser(rUser.user);
        this.loginChangeObx.next(rUser.user);
    }


    formatString(str: string, ...val: string[]) {
        for (let index = 0; index < val.length; index++) {
            str = str.replace(`{${index}}`, val[index]);
        }
        return str;
    }

    showSuccessSnack(matSnackBar: MatSnackBar, message: string, duration: number = 5000): void {
        if (message) {
            matSnackBar.open(message, undefined, {
                duration: duration,
                panelClass: ['green-snackbar'],
                horizontalPosition: 'center',
                verticalPosition: 'top',
            });
        }
    }

    showErrorSnack(matSnackBar: MatSnackBar, message: string, duration: number = 5000): void {
        if (!message) {
            message = 'We encountered an issue. Please try again later.';
        }

        matSnackBar.open(message, undefined, {
            duration: duration,
            panelClass: ['red-snackbar'],
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

    public saveAsFile(data: HttpResponse<Blob>, name: string): void {
        const blob = data.body;
        const url = window.URL.createObjectURL(blob ?? new Blob());
        const anchor = document.createElement('a');
        anchor.download = name;
        anchor.href = url;
        anchor.click();
    }

}
