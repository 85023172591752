<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>

        <!-- <div class="flex items-center w-full p-4 pl-6">
            <div class="flex items-center justify-center">
                <img
                    class="w-28"
                    src="assets/images/logo/logo.svg">
            </div>
        </div> -->

        <!-- User -->
        <div class="flex flex-col items-center w-full p-4 mt-6">
            <div class="relative w-22 h-22 border-[#FFFFFF] border-2 rounded-full bg-[#363636]">
                <img class="w-full h-full rounded-full" *ngIf="user.avatar" [src]="user.avatar" alt="User avatar">
                <!-- <h3 *ngIf="!user.avatar" class="text-white text-4xl font-medium text-center w-full h-full leading-[5.2rem]">BH</h3> -->
                <h3 *ngIf="!user.avatar"
                    class="text-white text-4xl font-medium text-center w-full h-full leading-[5.2rem]">{{getInitials()}}
                </h3>
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-2 mb-5">
                <div
                    class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{user.name}}
                </div>
                <div
                    class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{user.email}}
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container fuseVerticalNavigationContentFooter>
        <div class="flex flex-col w-full p-4 mt-2">
            <span
                class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-md font-medium text-secondary ml-4">FE
                Build: v{{feVersion ?? ''}}</span>
            <span
                class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-md font-medium text-secondary ml-4">BE
                Build: v{{beVersion ?? ''}}</span>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-2 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>

        <img [attr.src]="'../../../../assets/images/logo/logo.svg'" alt="" class="md:h-10 h-8">

        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <messages></messages>
            <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
            </button> -->

            <!-- <notifications></notifications> -->

            <!-- <div class="bg-[rgba(214,25,15,10%)] flex items-center p-1 px-3 rounded-full text-md">
                <img *ngIf="socketConnectionState" class="connected-icon"
                    src='../../../../assets/icons/connected.svg' />
                <img *ngIf="!socketConnectionState" class="connected-icon"
                    src='../../../../assets/icons/disconnected.svg' />
                <span class="ml-2 font-bold">{{parkingLocation?.parkingLocationCode ??
                    '-'}}</span>&nbsp;|&nbsp;{{parkingLocation?.name ?? '-'}}
            </div> -->

            <user [showAvatar]="false"></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->

</div>