import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { SystemService } from './services/system.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent {
    icons: Array<string> = [
        'dashboard',
        'log-report',
        'parking-setting',
        'master-mgmt',
        'parking-gate',
        'pos-devices',
        'kiosk',
        'holiday',
        'parking-fee',
        'user-management',
        'system-build',
        'parking-status',
        'profile',
        'sign-out',
        'app-user',
        'parking-area',
        'promo-code',
        'telegram-bot',
    ];

    constructor(
        private _systemService: SystemService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {
        this.icons.map(e => {
            this.iconRegistry.addSvgIcon(
                e, this.sanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/sidebar/${e}.svg`));
        });
    }

    ngOnInit(): void {
        this.loadServices();
    }

    async loadServices() {
        await this._systemService.getBeVersionInfo();
        await this._systemService.loadSystemConstValues();
    }
}
