import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { LayoutComponent } from 'app/layout/layout.component';
import { Const } from './const';
import { AccessGuard } from './guards/access.guard';
import { AuthGuard } from './guards/auth.guard';
import { NoAuthGuard } from './guards/noAuth.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
    },
    {
        path: 'signed-in-redirect',
        pathMatch: 'full',
        redirectTo: 'dashboard'
    },
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes') },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes') },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes') },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes') },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes') },
        ]
    },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes') }
        ]
    },

    // Admin routes
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'log-report',
        canActivate: [AuthGuard, AccessGuard(Const.AccessControls.LOG_READ_ALL)],
        canActivateChild: [AuthGuard, AccessGuard(Const.AccessControls.LOG_READ_ALL)],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        loadChildren: () => import('./modules/log-report/log-report.module').then(m => m.LogReportModule)
    },
    {
        path: 'parking-location',
        canActivate: [AuthGuard, AccessGuard(Const.AccessControls.PARK_LOCATIONS_READ_ALL)],
        canActivateChild: [AuthGuard, AccessGuard(Const.AccessControls.PARK_LOCATIONS_READ_ALL)],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        loadChildren: () => import('./modules/parking-location/parking-location.module').then(m => m.ParkingLocationModule)
    },
    {
        path: 'pos-devices',
        canActivate: [AuthGuard, AccessGuard(Const.AccessControls.POS_DEVICE_READ_ALL)],
        canActivateChild: [AuthGuard, AccessGuard(Const.AccessControls.POS_DEVICE_READ_ALL)],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        loadChildren: () => import('./modules/master-management/pos-devices/pos-devices.module').then(m => m.PosDevicesModule)
    },
    {
        path: 'gates',
        canActivate: [AuthGuard, AccessGuard(Const.AccessControls.PARKING_GATE_READ_ALL)],
        canActivateChild: [AuthGuard, AccessGuard(Const.AccessControls.PARKING_GATE_READ_ALL)],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        loadChildren: () => import('./modules/master-management/gates/gates.module').then(m => m.GatesModule)
    },
    {
        path: 'kiosk',
        canActivate: [AuthGuard, AccessGuard(Const.AccessControls.KIOSK_READ_ALL)],
        canActivateChild: [AuthGuard, AccessGuard(Const.AccessControls.KIOSK_READ_ALL)],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        loadChildren: () => import('./modules/master-management/kiosk/kiosk.module').then(m => m.KioskModule)
    },
    {
        path: 'holiday',
        canActivate: [AuthGuard, AccessGuard(Const.AccessControls.HOLIDAY_READ_ALL)],
        canActivateChild: [AuthGuard, AccessGuard(Const.AccessControls.HOLIDAY_READ_ALL)],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        loadChildren: () => import('./modules/master-management/holidays/holidays.module').then(m => m.HolidaysModule)
    },
    {
        path: 'user-management',
        canActivate: [AuthGuard, AccessGuard(Const.AccessControls.USER_READ_ALL)],
        canActivateChild: [AuthGuard, AccessGuard(Const.AccessControls.USER_READ_ALL)],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        loadChildren: () => import('./modules/user-management/user-management.module').then(m => m.UserManagementModule)
    },
    {
        path: 'system-build',
        canActivate: [AuthGuard, AccessGuard(Const.AccessControls.SYSTEM_BUILD_READ_ALL)],
        canActivateChild: [AuthGuard, AccessGuard(Const.AccessControls.SYSTEM_BUILD_READ_ALL)],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        loadChildren: () => import('./modules/system-build/system-build.module').then(m => m.SystemBuildModule)
    },
    {
        path: 'parking-fees-plans',
        canActivate: [AuthGuard, AccessGuard(Const.AccessControls.PARKING_FEE_SETUP_READ_ALL)],
        canActivateChild: [AuthGuard, AccessGuard(Const.AccessControls.PARKING_FEE_SETUP_READ_ALL)],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        loadChildren: () => import('./modules/master-management/parking-fees-plans/parking-fees-plans.module').then(m => m.ParkingFeesPlansModule)
    },
    {
        path: 'parking-status',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        loadChildren: () => import('./modules/parking-status/parking-status.module').then(m => m.ParkingStatusModule)
    },
    {
        path: 'app-user',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        loadChildren: () => import('./modules/app-user/app-user.module').then(m => m.AppUserModule)
    },
    {
        path: 'forbidden',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        loadChildren: () => import('./modules/auth/forbidden/forbidden.module').then(m => m.ForbiddenModule)
    },
    {
        path: '**',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        loadChildren: () => import('./modules/auth/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
    },
];
