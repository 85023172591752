import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls } from 'app/config';
import { LoginUser } from 'app/models/login-user';
import { PaginationResponse } from 'app/models/pagination-response';
import { AccessControlList, User } from 'app/models/user';
import { Observable, ReplaySubject, lastValueFrom, map, tap } from 'rxjs';
import { StorageService } from './storage.service';
import { UtilService } from './util.service';
import { Const } from 'app/const';

@Injectable({ providedIn: 'root' })
export class UserService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    accessWithRoute: Array<any> = [
        {
            access: Const.AccessControls.DASHBOARD_VIEW,
            route: ['dashboard']
        },
        {
            access: 'all',
            route: ['parking-status']
        },
        {
            access: Const.AccessControls.PARK_LOCATIONS_READ_ALL,
            route: ['parking-location']
        },
        {
            access: Const.AccessControls.SYSTEM_BUILD_READ_ALL,
            route: ['system-build']
        },
        {
            access: Const.AccessControls.LOG_READ_ALL,
            route: ['log-report']
        },
        {
            access: Const.AccessControls.USER_READ_ALL,
            route: ['user-management']
        },
        {
            access: Const.AccessControls.PARKING_GATE_READ_ALL,
            route: ['gates']
        },
        {
            access: Const.AccessControls.POS_DEVICE_READ_ALL,
            route: ['pos-devices']
        },
        {
            access: Const.AccessControls.KIOSK_READ_ALL,
            route: ['kiosk']
        },
        {
            access: Const.AccessControls.PARKING_FEE_SETUP_READ_ALL,
            route: ['parking-fees-plans']
        },
        {
            access: Const.AccessControls.HOLIDAY_READ_ALL,
            route: ['holiday']
        },
    ];

    constructor(
        private _httpClient: HttpClient,
        private _utilService: UtilService,
        private _storageService: StorageService,
        private http: HttpClient,
    ) {
        this.observedUser();
    }

    observedUser() {
        this._utilService.loginChangeObx.subscribe((it) => {
            this.user = it;
        });
    }

    set user(value: User) {
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    get(): Observable<User> {
        return this._httpClient.get<User>('api/common/user').pipe(
            tap((user) => {
                this._user.next(user);
            }),
        );
    }

    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', { user }).pipe(
            map((response) => {
                this._user.next(response);
            }),
        );
    }

    hasAccess(access: string | Array<string>): Boolean {
        const user = this._storageService.getCurrentUser();
        if (user.role == 'Super Admin') {
            return true;
        }
        if (typeof access === 'string') {
            if (user?.accessControls?.includes(access)) {
                return true;
            }
        } else {
            var hasAccess = false;
            access.map(e => {
                if (user?.accessControls?.includes(e)) {
                    hasAccess = true;
                }
            });
            return hasAccess;
        }
        return false;
    }

    getInitialRouteAccess(): string {
        const user = this._storageService.getCurrentUser();
        var route = null;
        this.accessWithRoute.map(e => {
            if ((user?.accessControls?.includes(e.access) || e.access == 'all') && !route) {
                route = e.route[0];
            }
        });
        return route ?? 'dashboard';
    }

    getAccessControlsList(): Promise<AccessControlList> {
        return lastValueFrom(this.http.get<AccessControlList>(ApiUrls.ACCESS_CONTROLS));
    }

    getAccessControlsRoleWiseList(): Promise<any> {
        return lastValueFrom(this.http.get<any>(ApiUrls.ACCESS_CONTROLS_ROLE_WISE));
    }

    getUserList(params: HttpParams): Promise<PaginationResponse<any>> {
        return lastValueFrom(this.http.get<PaginationResponse<any>>(ApiUrls.USERS, { params }));
    }

    fetchUser(): Promise<LoginUser> {
        return lastValueFrom(this.http.get<LoginUser>(ApiUrls.USER_FETCH));
    }

    createUser(payload: User): Promise<User> {
        return lastValueFrom(this.http.post<User>(ApiUrls.ADD_USERS, payload));
    }

    updateUser(userId: string, payload: any): Promise<User> {
        return lastValueFrom(this.http.patch<User>(`${ApiUrls.USERS}/${userId}`, payload));
    }

    getUserById(userId: string): Promise<User> {
        return lastValueFrom(this.http.get<User>(`${ApiUrls.USERS}/${userId}`));
    }
}
