/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Const } from 'app/const';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'dashboard',
        link: '/dashboard',
        access: Const.AccessControls.DASHBOARD_VIEW
    },
    {
        id: 'parking-status',
        title: 'Parking Status',
        type: 'basic',
        icon: 'parking-status',
        link: '/parking-status',
        access: 'all'
    },
    {
        id: 'log-report',
        title: 'Log Report',
        type: 'basic',
        icon: 'log-report',
        link: '/log-report',
        access: Const.AccessControls.LOG_READ_ALL
    },
    {
        id: 'UserManagement',
        title: 'User Management',
        type: 'basic',
        icon: 'user-management',
        link: '/user-management',
        access: Const.AccessControls.USER_READ_ALL
    },
    // {
    //     id: 'AppUser',
    //     title: 'App Users',
    //     type: 'basic',
    //     icon: 'app-user',
    //     link: '/app-user',
    //     access: Const.AccessControls.USER_READ_ALL
    // },
    {
        id: 'parking-location',
        title: 'Parking Location',
        type: 'basic',
        icon: 'parking-setting',
        link: '/parking-location',
        access: Const.AccessControls.PARK_LOCATIONS_READ_ALL
    },
    {
        id: 'system-build',
        title: 'System Builds',
        type: 'basic',
        icon: 'system-build',
        link: '/system-build',
        access: Const.AccessControls.SYSTEM_BUILD_READ_ALL
    },
    {
        id: 'master-management',
        title: 'Master Management',
        type: 'collapsable',
        icon: 'master-mgmt',
        access: [
            Const.AccessControls.PARKING_GATE_READ_ALL,
            Const.AccessControls.POS_DEVICE_READ_ALL,
            Const.AccessControls.KIOSK_READ_ALL,
            Const.AccessControls.PARKING_FEE_SETUP_READ_ALL,
            Const.AccessControls.HOLIDAY_READ_ALL,
            Const.AccessControls.PARKING_AREA_TYPE_READ_ALL
        ],
        children: [
            {
                id: 'Gates',
                title: 'Parking Gates',
                type: 'basic',
                icon: 'parking-gate',
                link: '/gates',
                access: Const.AccessControls.PARKING_GATE_READ_ALL
            },
            {
                id: 'ParkingArea',
                title: 'Parking Area',
                type: 'basic',
                icon: 'parking-area',
                link: '/parking-area',
                access: Const.AccessControls.PARKING_AREA_TYPE_READ_ALL
            },
            {
                id: 'POSDevices',
                title: 'POS Devices',
                type: 'basic',
                icon: 'pos-devices',
                link: '/pos-devices',
                access: Const.AccessControls.POS_DEVICE_READ_ALL
            },
            {
                id: 'Kiosk',
                title: 'Kiosk',
                type: 'basic',
                icon: 'kiosk',
                link: '/kiosk',
                access: Const.AccessControls.KIOSK_READ_ALL
            },
            {
                id: 'fee',
                title: 'Parking Fees Plan',
                type: 'basic',
                icon: 'parking-fee',
                link: '/parking-fees-plans',
                access: Const.AccessControls.PARKING_FEE_SETUP_READ_ALL
            },
            {
                id: 'Holiday',
                title: 'Holiday',
                type: 'basic',
                icon: 'holiday',
                link: '/holiday',
                access: Const.AccessControls.HOLIDAY_READ_ALL
            },
        ],
    }
];

export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
