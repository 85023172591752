export class Const {
    static AccessControls = {
        USER_CREATE: 'USER_CREATE',
        USER_UPDATE: 'USER_UPDATE',
        USER_DELETE: 'USER_DELETE',
        USER_READ_ALL: 'USER_READ_ALL',
        USER_READ: 'USER_READ',
        PARK_LOCATIONS_READ_ALL: 'PARK_LOCATIONS_READ_ALL',
        PARK_LOCATION_READ: 'PARK_LOCATION_READ',
        PARK_LOCATION_CREATE: 'PARK_LOCATION_CREATE',
        PARK_LOCATION_UPDATE: 'PARK_LOCATION_UPDATE',
        PARK_LOCATION_DELETE: 'PARK_LOCATION_DELETE',
        PARKING_FEE_SETUP_READ_ALL: 'PARKING_FEE_SETUP_READ_ALL',
        PARKING_FEE_SETUP_READ: 'PARKING_FEE_SETUP_READ',
        POS_DEVICE_READ_ALL: 'POS_DEVICE_READ_ALL',
        POS_DEVICE_READ: 'POS_DEVICE_READ',
        KIOSK_READ_ALL: 'KIOSK_READ_ALL',
        KIOSK_DEVICE_READ: 'KIOSK_DEVICE_READ',
        HOLIDAY_READ_ALL: 'HOLIDAY_READ_ALL',
        HOLIDAY_READ: 'HOLIDAY_READ',
        PARKING_GATE_READ_ALL: 'PARKING_GATE_READ_ALL',
        PARKING_GATE_READ: 'PARKING_GATE_READ',
        SEASON_PASS_READ_ALL: 'SEASON_PASS_READ_ALL',
        SEASON_PASS_READ: 'SEASON_PASS_READ',
        LOG_READ_ALL: 'LOG_READ_ALL',
        DASHBOARD_VIEW: 'DASHBOARD_VIEW',
        SYSTEM_BUILD_READ_ALL: 'SYSTEM_BUILD_READ_ALL',
        SYSTEM_BUILD_READ: 'SYSTEM_BUILD_READ',
        SYSTEM_BUILD_UPLOAD: 'SYSTEM_BUILD_UPLOAD',
        SYSTEM_BUILD_UPDATE: 'SYSTEM_BUILD_UPDATE',
        PARKING_AREA_TYPE_READ_ALL: 'PARKING_AREA_TYPE_READ_ALL',
        PARKING_AREA_TYPE_READ: 'PARKING_AREA_TYPE_READ',
    }
}
