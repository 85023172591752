import { Injectable } from '@angular/core';
import { SocketEvents } from 'app/config';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Socket, io } from 'socket.io-client';

@Injectable({ providedIn: 'root' })
export class SocketService {

    private socket?: Socket = null;
    // passingInfo = new BehaviorSubject<PassingInfo | null>(null);
    socketConnectionState = new BehaviorSubject<boolean>(false);

    constructor() { }

    async init() {
        if (this.socket != null) {
            this.socket?.disconnect();
            this.socket = null;
        }

        this.socket = io(environment.SOCKET_URL);

        this.socket.on('reconnect', async (x) => {
            this.socketLog(`Socket Reconnected: ${x}`);
            await this.connect();
            this.setSocketState();
        });
        this.socket.on('connection', async (x) => {
            this.socketLog(`Socket Connection: ${x}`);
            await this.connect();
            this.setSocketState();
        });
        this.socket.on('disconnect', (x) => {
            this.socketLog(`Socket Disconnected: ${x}`);
            this.setSocketState();
        });
        this.socket.on('exception', (x) => {
            this.socketLog(`Socket Exception: ${x}`);
            this.setSocketState();
        });
        this.socket.on('error', (x) => {
            this.socketLog(`Socket Error: ${x}`);
            this.setSocketState();
        });
        // this.socket.on(SocketEvents.PASSING_INFO, (data) => {
        //     this.passingInfo.next(data);
        //     console.log(data);
        // });
    }

    async connect() {
        if (this.socket != null) {
            this.socket?.connect();
            this.socketLog(`Socket Connected`);
            await this.subscribeEvent();
            this.socketLog(`Socket Event Subscribed`);
        }
    }

    async subscribeEvent() {
        let response = await this.socket?.emitWithAck(SocketEvents.SUBSCRIBE, { 'eventType': SocketEvents.PASSING_INFO });
        this.socketLog(JSON.stringify(response));
    }

    private socketLog(log: string) {
        console.log('%c' + log, 'color: blue');
    }

    private setSocketState() {
        this.socketConnectionState.next(this.socket?.connected ?? false);
    }
}
