import { environment } from "environments/environment";

export const GlobalVariable = Object.freeze({
    BASE_URL: environment.API_URL,
});

export const ApiUrls = Object.freeze({

    BE_INFO: `${GlobalVariable.BASE_URL}`,
    QR_LINK_DOWNLOAD_RECEIPT_PDF: `https://devapi.dashapp.asia/parkingTransaction/{0}/downloadReceiptPDF`,

    LOGIN_USER: `${GlobalVariable.BASE_URL}adminAuth/login`,
    ACCESS_CONTROLS: `${GlobalVariable.BASE_URL}adminAuth/accessControls`,
    ACCESS_CONTROLS_ROLE_WISE: `${GlobalVariable.BASE_URL}adminAuth/accessControlsRoleWise`,

    MEDIA_DOWNLOAD: `${GlobalVariable.BASE_URL}media/{0}/download/file`,
    MEDIA_UPLOAD: `${GlobalVariable.BASE_URL}media/upload`,

    USERS: `${GlobalVariable.BASE_URL}adminUser`,
    USER_FETCH: `${GlobalVariable.BASE_URL}adminUser/fetch`,
    ADD_USERS: `${GlobalVariable.BASE_URL}adminUser/addUser`,

    LOG: `${GlobalVariable.BASE_URL}log`,
    SYSTEM_CONSTANT_VALUES: `${GlobalVariable.BASE_URL}system/constantsValues`,
    SYSTEM_LATEST_BUILDS: `${GlobalVariable.BASE_URL}system/latestBuilds`,
    SYSTEM_APPLY_SERVER_UPDATE: `${GlobalVariable.BASE_URL}system/applyServerUpdate`,
    SYSTEM_APPLY_WEB_ADMIN_UPDATE: `${GlobalVariable.BASE_URL}system/applyWebAdminUpdate`,
    SYSTEM_LATEST_AVAILABLE_BUILD: `${GlobalVariable.BASE_URL}system/latestAvailableBuilds`,
    SYSTEM_CHIP_IN_PAYMENT_METHODS: `${GlobalVariable.BASE_URL}system/chipInPaymentMethods`,

    PARKING_LOCATION: `${GlobalVariable.BASE_URL}parkingLocation`,

    PARKING_GATE: `${GlobalVariable.BASE_URL}parkingGate`,

    POS_DEVICE: `${GlobalVariable.BASE_URL}posDevice`,

    KIOSK: `${GlobalVariable.BASE_URL}kiosk`,

    HOLIDAY: `${GlobalVariable.BASE_URL}holiday`,

    PARKING_ENTRY: `${GlobalVariable.BASE_URL}parkingEntry`,

    PARKING_TRANSACTION: `${GlobalVariable.BASE_URL}parkingTransaction`,

    PASSING_INFO: `${GlobalVariable.BASE_URL}passingInfo`,

    PARKING_FEE_SETUP: `${GlobalVariable.BASE_URL}parkingFeeSetup`,
    PARKING_FEE_SIMULATE: `${GlobalVariable.BASE_URL}parkingFeeSetup/simulate`,
    PARKING_FEE_SIMULATE_NESTED: `${GlobalVariable.BASE_URL}parkingFeeSetup/simulateNestedParking`,

    SYSTEM_BUILD: `${GlobalVariable.BASE_URL}systemBuild`,

    DASHBOARD_STATS: `${GlobalVariable.BASE_URL}report/dashboardStats`,
    COLLECTION_SUMMARY: `${GlobalVariable.BASE_URL}report/collectionSummary`,

    CUSTOMER: `${GlobalVariable.BASE_URL}customer`,
    CUSTOMER_VEHICLE: `${GlobalVariable.BASE_URL}customerVehicle`,
    WALLET_TRANSACTION: `${GlobalVariable.BASE_URL}walletTransaction`,

    PARKING_AREA: `${GlobalVariable.BASE_URL}parkingArea`,

    TELEGRAM_BOT: `${GlobalVariable.BASE_URL}telegramBot`,

    PROMO_CODE: `${GlobalVariable.BASE_URL}promoCode`,
    PROMO_CODE_GENERATE: `${GlobalVariable.BASE_URL}promoCode/generatePromoCodes`,
    PROMO_CODE_EXPORT: `${GlobalVariable.BASE_URL}promoCode/{0}/downloadPromoDetails`,
    GET_USAGE_DETAILS: `${GlobalVariable.BASE_URL}promoCode/{0}/usageDetails`,


});

export const SocketEvents = Object.freeze({
    SUBSCRIBE: 'subscribe',
    PASSING_INFO: 'PassingInfo',
});

export const StorageConst = Object.freeze({
    TOKEN: 'accessToken',
    CURRENT_USER: 'currentUser',
    PARKING_DETAILS: 'parkingDetails',
    PARKING_DETAILS_LOGO: 'appBranding',
});
